<script setup lang="ts">
withDefaults(defineProps<{
  isUser?: boolean
  cartCount: number
}>(), {
  isUser: false,
})

const emit = defineEmits<{
  (e: 'clickEnter'): void
  (e: 'clickCart'): void
}>()
</script>

<template>
  <div class="tabbar">
    <a href="https://05.ru" class="tabbar__el">
      <div class="tabbar__icon tabbar__icon_back" />
      <div class="tabbar__text">
        в 05.RU
      </div>
    </a>
    <nuxt-link to="/" class="tabbar__el">
      <div class="tabbar__icon tabbar__icon_home" />
      <div class="tabbar__text">
        Главная
      </div>
    </nuxt-link>
    <nuxt-link
      to="/cart/"
      class="tabbar__el"
      data-e2e="tabbar-cart"
      @click="emit('clickCart')"
    >
      <div class="tabbar__icon tabbar__icon_cart" />
      <div
        v-if="cartCount"
        class="tabbar__icon-marker"
        data-e2e="tabbar-cart-count"
      >
        {{ cartCount }}
      </div>
      <div class="tabbar__text">
        Корзина
      </div>
    </nuxt-link>
    <nuxt-link
      :to="isUser ? '/user/' : undefined"
      class="tabbar__el"
      data-e2e="tabbar-profile"
      @click="!isUser && emit('clickEnter')"
    >
      <div class="tabbar__icon tabbar__icon_profile" />
      <div class="tabbar__text tabbar__text_profile">
        {{ isUser ? 'Профиль' : 'Войти' }}
      </div>
    </nuxt-link>
  </div>
</template>

<style lang="postcss" scoped>
.tabbar {
  display: none;
  flex-direction: row;

  &__el {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 19px;
    margin-right: 6px;
    font-size: 14px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: left center;
    border-radius: 2px;

    &:last-child {
      margin-right: 0;
    }

    &_cart {
      width: 29px;
      height: 25px;
      background-image: url(./asset/cart.svg);
    }
  }

  @media (max-width: 800px) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: calc(var(--z-below-blackout) + 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 20px;
    padding-bottom: max(14px, env(safe-area-inset-bottom));
    background-color: white;
    border-top: 1px solid #efefef;

    .router-link-active {

      .tabbar__text {
        color: #E30613;
      }

      .tabbar__icon {
        filter: invert(15%) sepia(91%) saturate(7430%) hue-rotate(347deg) brightness(75%) contrast(120%);

        &_profile {
          background-image: url(./asset/user.svg);
        }

        &_cart {
          background-image: url(./asset/cart.svg);
        }

        &_home {
          background-image: url(./asset/home.svg);
        }

        &_back {
          background-image: url(./asset/arrow.svg);
        }
      }
    }

    &__el {
      position: relative;
      padding-left: 0;
      text-align: center;
      text-decoration: none;
    }

    &__icon-marker {
      position: absolute;
      top: -3px;
      right: -3px;
      min-width: 21px;
      max-width: 40px;
      padding: 0px 3px;
      overflow: hidden;
      font-size: 12px;
      color: #fff;
      text-align: center;
      text-overflow: ellipsis;
      background-color: #e30613;
      border: 1px solid #FFF;
      border-radius: 9px;

      &-label {
        position: absolute;
        top: -5px;
        right: 4px;
        min-width: 18px;
        height: 18px;
        padding: 2px;
        font-size: 8px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        background-color: #e30613;
        border: 2px solid var(--white);
        border-radius: 13px;
        animation: bounce 0.3s 0s 2;
      }
    }

    &__text {
      margin-top: 4px;
      font-size: 12px;
      line-height: 12px;
      color: #a9afc1;
    }

    &__icon {

      &_profile,
      &_back,
      &_cart,
      &_home {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      &_profile {
        padding: 14px;
        background-image: url(./asset/user.svg);
      }

      &_cart {
        padding: 14px 13px;
        background-image: url(./asset/cart.svg);
      }

      &_home {
        position: relative;
        padding: 14px 12px;
        background-image: url(./asset/home.svg);
      }

      &_back {
        padding: 14px;
        background-image: url(./asset/arrow.svg);
      }
    }
  }

}
</style>

<script setup lang="ts">
import MobileDetect from 'mobile-detect'

const url = ref('https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US')

onMounted(() => {
  if (new MobileDetect(navigator.userAgent).os() === 'iOS' || new MobileDetect(navigator.userAgent).userAgent() === 'Safari')
    url.value = 'https://apps.apple.com/us/app/05-ru/id1539023015?l=ru/'
  else
    url.value = 'https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US'
})
</script>

<template>
  <nuxt-link
    :to="url"
    target="_blank"
    class="header-bar link link_wu"
  >
    <div class="header-bar__content">
      <img
        src="./asset/blueberry.jpg"
        srcset="
       ./asset/blueberry.jpg 1x,
       ./asset/blueberry@2x.jpg 2x,
       ./asset/blueberry@3x.jpg 3x"
        alt="голубика"
        class="header-bar__blueberry"
      >

      <img
        src="./asset/peach.jpg"
        srcset="
       ./asset/peach.jpg 1x,
       ./asset/peach@2x.jpg 2x,
       ./asset/peach@3x.jpg 3x"
        alt="персик"
        class="header-bar__peach"
      >

      <img
        src="./asset/phone.jpg"
        srcset="
       ./asset/phone.jpg 1x,
       ./asset/phone@2x.jpg 2x,
       ./asset/phone@3x.jpg 3x"
        alt="загрузить приложение 05 на телефон"
        class="header-bar__phone"
      >

      <div class="header-bar__app">
        <img
          src="./asset/appstore.jpg"
          srcset="
       ./asset/appstore.jpg 1x,
       ./asset/appstore@2x.jpg 2x,
       ./asset/appstore@3x.jpg 3x"
          alt="appstore"
          class="header-bar__app-appstore"
        >

        <div class="header-bar__app-text">
          в приложении <br class="header-bar__app-text-break"> ещё удобнее
        </div>

        <img
          src="./asset/phone-mobile.png"
          srcset="
       ./asset/phone-mobile.png 1x,
       ./asset/phone-mobile@2x.png 2x,
       ./asset/phone-mobile@3x.png 3x"
          alt="загрузить приложение 05 на телефон"
          class="header-bar__phone-mobile"
        >

        <img
          src="./asset/googleplay.jpg"
          srcset="
       ./asset/googleplay.jpg 1x,
       ./asset/googleplay@2x.jpg 2x,
       ./asset/googleplay@3x.jpg 3x"
          alt="googleplay"
          class="header-bar__app-googleplay"
        >

        <h3 class="header-bar__app-download-desktop">
          скачать
        </h3>
        <h3 class="header-bar__app-download-mobile">
          <img
            src="./asset/arrow-right.svg"
            alt="скачать"
            class="header-bar__app-download-mobile-svg"
          >
        </h3>
      </div>

      <img
        src="./asset/phone2.jpg"
        srcset="
       ./asset/phone2.jpg 1x,
       ./asset/phone2@2x.jpg 2x,
       ./asset/phone2@3x.jpg 3x"
        alt="Доставка продуктов за 30 минут"
        class="header-bar__phone2"
      >

      <img
        src="./asset/broccoli.jpg"
        srcset="
       ./asset/broccoli.jpg 1x,
       ./asset/broccoli@2x.jpg 2x,
       ./asset/broccoli@3x.jpg 3x"
        alt="броколи"
        class="header-bar__broccoli"
      >

      <img
        src="./asset/cheese.jpg"
        srcset="
       ./asset/cheese.jpg 1x,
       ./asset/cheese@2x.jpg 2x,
       ./asset/cheese@3x.jpg 3x"
        alt="сыр"
        class="header-bar__cheese"
      >

      <img
        src="./asset/peach-mobile.png"
        srcset="
       ./asset/peach-mobile.png 1x,
       ./asset/peach-mobile@2x.png 2x,
       ./asset/peach-mobile@3x.png 3x"
        alt="персик"
        class="header-bar__peach-mobile"
      >
    </div>
  </nuxt-link>
</template>

<style scoped lang="postcss">
.header-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  overflow: hidden;
  background: #EDFB4B;

  @media (max-width: 800px) {
    height: 40px;
  }

  &__content {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    max-width: 1920px;
    height: 100%;

    @media (max-width: 800px) {
      flex-shrink: 1;
      width: 100%;
      padding: 0 14px 0 10px;
    }
  }

  &__blueberry {
    flex-shrink: 0;
    width: 118px;
    height: 50px;
    margin-left: 88px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__peach {
    flex-shrink: 0;
    width: 108px;
    height: 50px;
    margin-left: 105px;

    @media (max-width: 1300px) {
      margin-left: 15px;
    }

    @media (max-width: 800px) {
      display: none;
    }

    &-mobile {
      display: none;

      @media (max-width: 800px) {
        position: absolute;
        top: 0;
        right: 15px;
        display: block;
        width: 50px;
        height: 40px;
      }

      @media (max-width: 500px) {
        display: none;
      }
    }
  }

  &__phone {
    flex-shrink: 0;
    width: 232px;
    height: 50px;
    margin-left: 43px;

    @media (max-width: 800px) {
      position: absolute;
      top: 0;
      right: 32px;
      z-index: 0;
      order: 1;
      width: 158px;
      height: 40px;
    }

    @media (max-width: 500px) {
      display: none;
    }

    &-mobile {
      display: none;

      @media (max-width: 500px) {
        display: block;
        flex-shrink: 0;
        order: 1;
        width: 99px;
        height: 40px;
      }
    }
  }

  &__app {
    display: flex;
    gap: 13px;
    align-items: center;
    height: 100%;
    margin-left: 27px;

    @media (max-width: 800px) {
      z-index: 1;
      gap: 5px  ;
      margin-left: 0;
    }

    @media (max-width: 500px) {
      gap: 6px;
      width: 302px;
      margin: 0 auto;
    }

    &-appstore {
      flex-shrink: 0;
      width: 34px;
      height: 34px;

      @media (max-width: 800px) {
        order: 2;
        width: 21px;
        height: 21px;
      }

      @media (max-width: 500px) {
        width: 26px;
        height: 26px;
      }
    }

    &-text {
      flex-shrink: 0;
      font-size: 24px;
      font-weight: 600;
      color: #000;
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 1300px) {
        font-size: 17px;
        font-weight: 600;
        line-height: 18px;
      }

      @media (max-width: 800px) {
        order: 1;
        margin-right: 3px;

        font-size: 12px;
        line-height: 12px;
        text-align: start;
      }

      &-break {
        display: none;

        @media (max-width: 1300px) {
          display: block;
        }
      }
    }

    &-googleplay {
      flex-shrink: 0;
      width: 34px;
      height: 34px;

      @media (max-width: 800px) {
        order: 3;
        width: 21px;
        height: 21px;
      }

      @media (max-width: 500px) {
        width: 26px;
        height: 26px;
      }
    }

    &-download {

      &-desktop {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 34px;

        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #FFF;
        text-transform:uppercase ;
        background: #FF1F1F;
        border-radius: 132px;

        @media (max-width: 800px) {
          order: 4;
          width: 68px;
          height: 21px;

          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px;
        }

        @media (max-width: 500px) {
          display: none;
        }
      }

      &-mobile {
        display: none;

        @media (max-width: 500px) {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          order: 3;
          width: 26px;
          height: 26px;
          background: #FF1F1F;
          border-radius: 50%;
        }

        &-svg {
          width: 6px;
          height: 13px;
        }
      }

    }
  }

  &__phone2 {
    flex-shrink: 0;
    width: 239px;
    height: 50px;
    margin-left: 25px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__broccoli {
    flex-shrink: 0;
    width: 97px;
    height: 50px;
    margin-left: 41px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__cheese {
    flex-shrink: 0;
    width: 135px;
    height: 50px;
    margin-right: 25px;
    margin-left: 56px;

    @media (max-width: 800px) {
      display: none;
    }
  }
}
</style>
